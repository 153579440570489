import { useWindowSize } from "react-use";
import styled from "styled-components";

import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import MailIcon from '@mui/icons-material/Mail';
import { Bio } from '../../data/constants';

import profileImage from '../../images/CoolPic.jpeg';
import { RollingText } from "./RollingText";
import { HomeHighlights } from "./HomeHighlights";

const iam = [
    "Developer!",
    "Learner!",
    "Programmer!",
]

const Wrapper = styled.section`
    display: flex;
    align-items: flex-start;

    @media (max-width: 1200px) {
        flex-direction: column;
        gap: 32px;
        padding-top: 40px;
    }
`

const ImageContainer = styled.div`
    position: sticky;
    top: 120px;
    flex: 1 2 45%;
    background-color: hsl(266, 100%, 95%);

    @media (max-width: 1200px) {
        flex: auto;
        width: calc(50% + min(200px, 40vw) + 24px);
        position: initial;
        top: initial;
    }
`
const InfoContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    flex: 1 1 55%;
    padding: 0 96px;
    gap: 32px;
    
    @media (max-width: 1300px) {
        padding: 0 48px;
    }

    @media (max-width: 1200px) {
        padding: 0 32px;
    }
`
const HeadLine = styled.div`
    font-size: 48px;
    font-weight: 200;
    margin: 28px 0;

    @media (max-width: 600px){
        font-size: 36px;
    }

    @media (max-width: 400px){
        font-size: 28px;
    }
`
const ThemeColor = styled.span`
    color: hsl(266, 100%, 60%);
`
const MySelf = styled.div`
    cursor: crosshair;
    user-select: none;
    span, strong {
        transition: all 150ms ease-in-out;
    }

    &:hover {
        span {
            opacity: 0.5;
        }
        strong {
            color: hsl(266, 100%, 60%)
        }
    }
`
const Profile = styled.img`
    position: relative;
    float: right;
    left: -24px;
    top: -24px;
    width: 400px;
    height: auto;
    max-width: 80vw;
`
const SocialMediaIcons = styled.div`
    display: flex;
    flex-direction: column;
    float: left;
    padding: 16px;
    & > * {
        transition: all 250ms ease-in;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            transition: all 250ms ease-out;
            background-color: hsl(0, 0%, 100%);
            border-radius: 50%;
        }
    }
`;

const SocialMediaIcon = styled.a`
  display: inline-block;
  margin: 0 1rem;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.text_primary};
  transition: color 0.2s ease-in-out;
  margin-bottom: 0.75rem;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }     
`;

export const HomeHeader = () => {
    const { width } = useWindowSize()

    return (
        <Wrapper id="about">
            <ImageContainer>  
                {width > 600 && <SocialMediaIcons>
                    <SocialMediaIcon href={Bio.linkedin} target="display"><LinkedInIcon /></SocialMediaIcon>
                    <SocialMediaIcon href={Bio.twitter} target="display"><TwitterIcon /></SocialMediaIcon>
                    <SocialMediaIcon href={Bio.github} target="display"><GitHubIcon /></SocialMediaIcon>
                    <SocialMediaIcon href={`mailto:${Bio.mail}`} target="display"><MailIcon /></SocialMediaIcon>
                </SocialMediaIcons>}
                <Profile loading="lazy" src={profileImage} alt="" width={413} height={550} />
            </ImageContainer>
            <InfoContainer>
            <HeadLine><span>I am</span> <ThemeColor><RollingText sentences={iam}/></ThemeColor></HeadLine>
                <MySelf>
                I'm a <strong>Software Engineer</strong> with a passion for building <strong>scalable</strong> and <strong>efficient</strong> solutions. 
                In my free time, I enjoy diving into engineering blogs from tech companies, learning how they design, scale, and optimize their systems to handle complex challenges.
                I'm passionate about competitive programming and enjoy participating in contests.
                </MySelf>
                <HomeHighlights />
            </InfoContainer> 
        </Wrapper>
    );
}
